<template>

    <Page>
        <template v-slot:contenttopbarleft>
            <h1>Persoonsgegevens</h1>
        </template>

        <v-row>
            <v-col lg="6" sm="12">
                <v-card class="content">
                    <v-card-text>
                        <v-form ref="form" lazy-validation>
                            <Form>
                                <FormRow label="Naam">
                                    <TextField placeholder="Naam"  :rules="[(v)=>!!v]" v-model="name"></TextField>
                                </FormRow>
                                <FormRow col-value-right >
                                    <ActionButton main primary @click='onSave'>Opslaan</ActionButton>
                                </FormRow>
                            </Form>
                        </v-form>
                    </v-card-text>
                </v-card>    
            </v-col>
        </v-row>
                
        <v-row>
            <v-col lg="6" sm="12">
                <v-card class=" content">
                    <v-card-text>
                        <Form>
                            <FormRow label="Gebruikersnaam">
                                <TextField disabled placeholder="Gebruikersnaam" v-model="user.email"></TextField>
                            </FormRow>
                            <FormRow col-value-right >
                                <ActionLinkButton action="auth.changeusername"></ActionLinkButton>
                            </FormRow>
                        </Form>
                    </v-card-text>
                </v-card>    
            </v-col>
        </v-row>
            
    </Page>

</template>

<script>

import Page from '@/overrides/auth/user/Page.vue'
import user from '@app/user'
import Form from '@controls/forms/Form'
import FormColumn from '@controls/forms/FormColumn'
import FormRow from '@controls/forms/FormRow'
import TextField from '@controls/input/TextField'
import ActionButton from '@shared/ui/controls/buttons/ActionButton'
import ActionLinkButton from '@controls/buttons/ActionLinkButton'

export default {
    name: 'Login',
    components: {
        Page, 
        Form,
        FormColumn,
        FormRow,
        TextField, 
        ActionButton,
        ActionLinkButton
    }, 
    data: function() {
        return {
            name:  "",
            email: "",
        }
    },   
    methods: {
        onSave: function() {
            this.user.updateUser({
                name: this.name, 
            })
        },
    },
    mounted: function() {
        this.name = this.user.name;
        this.$refs.form.resetValidation();
    },
    setup() {
        return {
            user: user
        }
    }
}
</script>



